<script>
export default {
  name: 'ToggleableListItem',
  props: {
    item: {
      type: Object
    }
  }
}
</script>
<template>
  <div class="toggleable-list-item--container" @click="$emit('showModalComponent')">
    <div class="toggleable-list-item--left" >
      <v-img :src="item.image" height="40px" width="40px"></v-img>
      <div class="toggleable-list-item--info">
        <p class="toggleable-list-item--title">{{ item.title }}</p>
        <span class="toggleable-list-item--description">{{ item.description  }}</span>
      </div>
    </div>
    <div class="toggleable-list-item--right">
      <v-switch :input-value="item.inputValue" @change="val => $emit('change', val)" color="#2FA84F" inset hide-details="auto" :disabled="item.isDisabled"></v-switch>
    </div>
  </div>
</template>
<style lang="scss">
.toggleable-list-item--container {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 16px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  .toggleable-list-item--left {
    display: flex;
    align-items: center;
    .v-image {
      margin-right: 16px;
      border-radius: 4px;
    }
    .toggleable-list-item--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #212121;
      margin-bottom: 0;
    }
    .toggleable-list-item--description {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #A0A0A0;
    }
  }
  .toggleable-list-item--right {
    display: flex;
    align-items: center;
    .v-input--selection-controls {
      margin-top: 0;
    }
  }
}
</style>
