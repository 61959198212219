<script>
import ToggleableListItem from '@/components/general/ToggleableListItem'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import Loading from '../../../components/general/Loading.vue'
export default {
  name: 'Support',
  components: {
    ToggleableListItem,
    Breadcrumbs,
    Loading
  },
  data () {
    return {
      isReady: false,
      company: {
        debugMode: '',
        allowSupport: ''
      }
    }
  },
  created () {
    this.getSupport()
  },
  methods: {
    getSupport () {
      this.$store.dispatch('attemptGetSupport', this.$store.getters.getLanguage.substring(0, 2))
        .then(({ data }) => {
          this.company.allowSupport = data.allowSupport
          this.isReady = true
        })
    },
    updateSupport (value) {
      this.$store.dispatch('attemptPutSupport', value)
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'admin.center:menu.6:title', href: this.$route.path }]
      return path
    }
  }
}
</script>
<template>
  <section class="support--container center-small">
  <div class="default-header">
    <breadcrumbs :items="breadcrumbsPaths"/>
    <h2>{{ $t('admin.center:support.title') }}</h2>
  </div>
  <loading v-if="!isReady"/>
  <toggleable-list-item v-else @change="val => updateSupport(val)" :item="{ inputValue: company.allowSupport, title: $t('admin.center.support:item'), description: $t('admin.center.support:item.description'), image: '/assets/images/Support/Support.png', isDisabled: false }"></toggleable-list-item>
  </section>
</template>
